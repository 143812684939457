import { template as template_6406ecf8ec0d42b7bd0c07dedb80be8c } from "@ember/template-compiler";
const FKControlMenuContainer = template_6406ecf8ec0d42b7bd0c07dedb80be8c(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
