import { template as template_c7af20d6e2ca4a549d03feccdc9b0675 } from "@ember/template-compiler";
const FKLabel = template_c7af20d6e2ca4a549d03feccdc9b0675(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
